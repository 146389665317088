/*!
* Tabler v1.0.0-beta17 (https://tabler.io)
* @version 1.0.0-beta17
* @link https://tabler.io
* Copyright 2018-2023 The Tabler Authors
* Copyright 2018-2023 codecalm.net Paweł Kuna
* Licensed under MIT (https://github.com/tabler/tabler/blob/master/LICENSE)
*/
.flag {
  position: relative;
  display: inline-block;
  width: 1.33333em;
  height: 1em;
  vertical-align: -3px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border: var(--tblr-border-size) var(--tblr-border-style)
    var(--tblr-border-color);
  border-radius: var(--tblr-border-radius);
}
.flag-country-br {
  background-image: url("../img/flags/br.svg");
}
.flag-country-cn {
  background-image: url("../img/flags/cn.svg");
}
.flag-country-de {
  background-image: url("../img/flags/de.svg");
}
.flag-country-es {
  background-image: url("../img/flags/es.svg");
}
.flag-country-fr {
  background-image: url("../img/flags/fr.svg");
}
.flag-country-gr {
  background-image: url("../img/flags/gr.svg");
}
.flag-country-in {
  background-image: url("../img/flags/in.svg");
}
.flag-country-it {
  background-image: url("../img/flags/it.svg");
}
.flag-country-jp {
  background-image: url("../img/flags/jp.svg");
}
.flag-country-nl {
  background-image: url("../img/flags/nl.svg");
}
.flag-country-ru {
  background-image: url("../img/flags/ru.svg");
}
.flag-country-sa {
  background-image: url("../img/flags/sa.svg");
}
.flag-country-se {
  background-image: url("../img/flags/se.svg");
}
.flag-country-tr {
  background-image: url("../img/flags/tr.svg");
}
.flag-country-us {
  background-image: url("../img/flags/us.svg");
}
.flag-xs {
  width: 1.5rem;
  height: 1.125rem;
}

.flag-sm {
  width: 2rem;
  height: 1.5rem;
}

.flag-md {
  width: 4rem;
  height: 3rem;
}

.flag-lg {
  width: 5.5rem;
  height: 4.125rem;
}

.flag-xl {
  width: 7rem;
  height: 5.25rem;
}

.flag-2xl {
  width: 11rem;
  height: 8.25rem;
}
