@keyframes tada {
  /* Start */
  0% {
    transform: scale3d(1, 1, 1);
  }

  /* Transform A */
  3%,
  7% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }

  /* Transform B */
  8%,
  13%,
  17% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  /* Transform C */
  10% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  /* Pause (Maintain end state) */
  17%,
  100% {
    transform: scale3d(1, 1, 1);
  }
}

.tada {
  animation: tada 12s ease-in-out infinite;
}